<template>
  <div class="CircleButton" :style="`--size:${size ? size : `${16 * 4}px`};--background:${background || $dcolor};`" @click="$emit('click')">
    <div class="CircleButtonIcon">
      <figure class="CircleButtonFigure"><iconic :name="icon" /></figure>
    </div>
    <div class="CircleButtonLabel" v-if="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: ["icon", "label", "size", "background"],
};
</script>

<style lang="scss" scoped>
.CircleButton {
  position: relative;
  cursor: pointer;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  user-select: none;
}

.CircleButtonIcon {
  font-size: calc(var(--size) / 2);
  .iconic {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.CircleButtonLabel {
  padding: $mpadding/2;
}

.CircleButtonFigure {
  background-color: var(--background);
  color: #fff;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  max-height: var(--size);
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CircleButton:active {
  .CircleButtonFigure {
    opacity: 0.5;
  }
}
</style>
